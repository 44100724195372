import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGetShoppingCartDetailsQuery } from '@/shoppingCart/services/shopping-cart.service';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { useGetPickerDetailsQuery } from '@/services/picker.service';

import { PickerItem } from '@/feed/ts/picker.types';
import { DiContainer } from '@/di/di-container';
import LogoImage from './icons/logo.svg';
import iconBell from './icons/bell.svg';
import iconUser from './icons/user.svg';
import { Badge, Modal } from 'antd';
import { Button } from '@/configurator/components/shared';
import { useNotAuthModal } from '@/shared/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/services/store';
import { SquaresPlus, CartIcon, OrderIcon } from './icons';
import { useGetOrdersQuery } from '@/orders-list/services';
import { useGetProfileQuery } from '@/profile/services/profile.service';

const clientStorageService = DiContainer.get(ClientStorageService);

export function PrimaryLayoutHeader() {
  const sessionId = clientStorageService.getUUID();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, refetch } = useGetShoppingCartDetailsQuery({
    session: sessionId,
    sharedId: new URLSearchParams(window.location.search).get('shared_uuid')});

  const { data: profileData } = useGetProfileQuery(sessionId);

  const { data: orders } = useGetOrdersQuery(sessionId);

  const { isAuthorized } = useSelector((state: RootState) => state.auth);
  const { openNotAuthModal, NotAuthModal } = useNotAuthModal({
    titleAuth: 'Внимание',
    titleNotAuth: 'Требуется авторизация',
    okAuth: 'Продолжить',
    okNotAuth: 'Войти',
  });
  const { data: pickerData } = useGetPickerDetailsQuery(sessionId);

  const isUserOnDefaultMode = location.search.includes('default');

  const configId = location.pathname.split('/')[2];

  const currentConfig = pickerData?.data?.find(
    (item: PickerItem) => item.id.toString() === configId
  );

  if (profileData) {
    localStorage.setItem('owner_uuid', profileData.data.attributes.uuid);
  }

  const openCallibriCall = () => {
    const callibriIcon = document.getElementsByClassName(
      'callibri_wrap_pict_tab'
    )[0] as HTMLElement;
    if (callibriIcon) {
      callibriIcon.click();
      document.getElementById('switchForCall')?.click();
    } else {
      const callibriIcon2 = document.getElementsByClassName(
        'callibri_hook_avatar'
      )[0] as HTMLElement;
      if (callibriIcon2) {
        callibriIcon2.click();
        document.getElementById('switchForCall')?.click();
      }
    }
  };

  const onNavigateClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isAuthorized) {
      openNotAuthModal();
      return;
    }
    if (configId && currentConfig?.previously_configured) {
      Modal.confirm({
        title: 'Подтвердите действие',
        content: 'Для перехода в конфигуратор, Вам нужно сохранить изменения.',
        okText: 'продолжить',
        cancelText: 'назад',
      });
    } else {
      navigate('/configurator');
    }
  };

  useEffect(() => {
    refetch();
  }, [isAuthorized]);

  useEffect(() => {
    document.addEventListener('onShoppingCartUpdate', refetch);
    return () => {
      document.removeEventListener('onShoppingCartUpdate', refetch);
    };
  }, []);

  const page = location.pathname.split('/').pop();

  return (
    <div className="header w-full ">
      <NotAuthModal />
      <div className="header__inner mx-auto max-w-[1440px] w-[90%] flex justify-between items-center py-[16px]">
        <div className="header__col flex items-center">
          <Link className="logo__button inline-block mr-[32px]" to="/">
            <img width={100} height={28} src={LogoImage} />
          </Link>
          <a
            className="phone_number mr-[24px] hover:text-black"
            href="tel:+788005559983"
            target="_parent">
            8 (800) 555 99 83
          </a>
          <Button
            className="call_button px-[16px] py-[8px] text-[16px] font-medium"
            onClick={openCallibriCall}>
            Обратный звонок
          </Button>
        </div>
        <div className="header__col flex">
          <ul className="buttons_group flex items-center gap-[24px] mr-[24px] list-none">
            <li key={1}>
              <Link
                to={'/configurator'}
                className="header__link flex items-center select-none transition active:opacity-50 hover:opacity-50"
                onClick={onNavigateClick}>
                <SquaresPlus
                  className="mr-[8px]"
                  stroke={page === 'configurator' ? '#007E3C' : '#292E3A'}
                />
                <span
                  className={
                    'font-normal text-[16px]' +
                    (page === 'configurator'
                      ? ' text-green-main underline underline-offset-4'
                      : ' text-black')
                  }>
                  Конфигуратор
                </span>
              </Link>
            </li>
            <li key={2}>
              <Link
                to={'/shopping-cart'}
                className={`header__link flex items-center select-none active:opacity-50 hover:opacity-50 ${
                  isUserOnDefaultMode || currentConfig?.previously_configured
                    ? 'pointer-events-none'
                    : 'pointer-events-auto'
                }`}>
                <CartIcon
                  stroke={page === 'shopping-cart' ? '#007E3C' : '#292E3A'}
                />
                <Badge
                  size="small"
                  offset={[0, -3]}
                  count={data?.total_quantity || undefined}>
                  <span
                    className={
                      'font-normal text-[16px]' +
                      (page === 'shopping-cart'
                        ? ' text-green-main underline underline-offset-4'
                        : ' text-black')
                    }>
                    Корзина
                  </span>
                </Badge>
              </Link>
            </li>
            <li key={3}>
              <Link
                to={'/order_list'}
                onClick={(e) => {
                  if (!isAuthorized || orders?.data?.length === 0) {
                    e.preventDefault();
                    openNotAuthModal();
                  }
                }}
                className={`header__link flex items-center select-none active:opacity-50 hover:opacity-50 ${
                  isUserOnDefaultMode || currentConfig?.previously_configured
                    ? 'pointer-events-none'
                    : 'pointer-events-auto'
                }`}>
                <OrderIcon
                  stroke={page === 'order_list' ? '#007E3C' : '#292E3A'}
                />
                <span
                  className={
                    'font-normal text-[16px]' +
                    (page === 'order_list'
                      ? ' text-green-main underline underline-offset-4'
                      : ' text-black')
                  }>
                  Заказы
                </span>
              </Link>
            </li>

            <li key={4}>
              <Link
                to={'/'}
                onClick={(e) => {
                  if (!isAuthorized) {
                    e.preventDefault();
                    openNotAuthModal();
                  }
                }}
                className="header__link flex items-center select-none active:opacity-50 hover:opacity-50">
                <img className="" src={iconBell} />
              </Link>
            </li>
          </ul>
          <div className="auth">
            <Link
              className="flex items-center active:opacity-75 hover:opacity-50"
              onClick={(e) => {
                if (!isAuthorized) {
                  return;
                }
                e.preventDefault();
                navigate('/profile');
              }}
              to={`https://sso.liliani.ru/login?client_id=99bee193-5fa9-4c51-8d79-4b62f2e1f74d&redirect_url=${window.location.href}`}>
              <span
                className={
                  'block h-[25px] mr-[14px] leading-6' +
                  (page === 'profile'
                    ? ' text-green-main underline underline-offset-4'
                    : ' text-black')
                }>
                {isAuthorized ? 'Профиль' : 'Войти'}
              </span>
              <img src={iconUser} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
