import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IInitialStepState,
  setCurrentStep,
  setSelectedStep,
} from '@/feed/model';
import { IStep } from '../index.component';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
import { ICurrentConfigItem, IOptionsData } from '@/feed/ts';
import { MatrixTable } from '../../matrix-modal/components';
import { Modal, notification } from 'antd';
import {
  useGetConfigurationCostMutation,
  useGetConfigurationStepsQuery,
  useGetNextStepItemsMutation,
} from '@/services/configurator.service';
import {
  useSetConfigurationMutation,
  useMarkBunkerAsConfiguredMutation,
  useApplyConfigurationMutation,
} from '@/services/picker.service';
import { DiContainer } from '@/di/di-container';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { handleApplyExpert } from '@/feed/functions/handle-apply-expert.functions';
import { Suggestion } from '@/configurator/ts';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { HeaderBar } from './header-bar.component';
import { debounce } from '@/shared/utils/debounce';
import ScrollToRightButton from './ScrollToRightButton';
const clientStorageService = DiContainer.get(ClientStorageService);

export const ExpertMode = ({
  id,
  stepData,
  // open,
  closeFn,
  // steps,
  currentItem,
  currentConfig,
  activeConfigItem,
  setActiveConfigItem,
  optionsData,
  getCurrentItem,
  pickerData,
  refetchData,
  steps,
  mode,
}: IExpertMode) => {
  const [setConfiguration, { isError, isSuccess }] =
    useSetConfigurationMutation();

  const [
    markBunkerAsConfigured,
    { isError: isConfiguredError, isSuccess: isConfiguredSuccess },
  ] = useMarkBunkerAsConfiguredMutation();

  const [
    applyConfiguration,
    {
      isError: isApplyConfigurationError,
      isSuccess: isApplyConfigurationSuccess,
    },
  ] = useApplyConfigurationMutation();

  const navigate = useNavigate();
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [getActualCost] = useGetConfigurationCostMutation();

  useEffect(() => {
    setSelectedItemsCount(
      dataSource
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item: any) => item[currentConfig()?.name + '_val'])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: number, item: any) => (item === true ? acc + 1 : acc), 0)
    );
  }, [currentConfig, dataSource, selectedItemsCount]);

  const {
    data: configItems,
    refetch,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetConfigurationStepsQuery({
    session: clientStorageService.getUUID(),
    category_id: stepData?.selected_step,
    picker_id: id,
  });

  const [configurationProfit, setConfigurationProfit] = useState(0);

  useEffect(() => {
    if (suggestions.length) {
      Modal.confirm({
        title: 'Необходимы действия',
        content: suggestions[0].message,
        okText: 'Перейти',
        cancelText: 'Закрыть',
        onOk: () => {
          setSelectedStep(suggestions[0].id);
          setCurrentStep(suggestions[0].id);
        },
      });
    }
  }, [suggestions]);

  async function getCost() {
    const res = await getActualCost({
      session: clientStorageService.getUUID(),
      picker_id: id,
    }).unwrap();
    setConfigurationProfit(res.data.actual_cost);
  }
  useEffect(() => {
    debounce(getCost);
  }, [configItems]);
  useEffect(() => {
    getCost();
  }, []);

  const [getConfigItemsFoo, { isLoading: isConfigItemsLoading }] =
    useGetNextStepItemsMutation();

  const getNextStepItems = async (catId: number) => {
    const res = await getConfigItemsFoo({
      session: clientStorageService.getUUID(),
      category_id: catId,
      picker_id: id,
    });
    return res;
  };

  const handleApply = (
    save = false,
    switchToNextStep = true,
    step: number | null = null
  ) => {
    setIsButtonsDisabled(true);
    handleApplyExpert({
      switchToNextStep,
      step,
      configItems,
      currentConfig,
      optionsData,
      dataSource,
      clientStorageService,
      setConfiguration,
      stepData,
      setSelectedStep,
      setCurrentStep,
      setActiveConfigItem,
      closeFn,
      activeConfigItem,
      refetch,
      getNextStepItems,
      markBunkerAsConfigured,
      applyConfiguration,
      pickerId: id,
      navigate,
      setSuggestions,
      save,
    }).finally(() => {
      setIsButtonsDisabled(false);
    });
  };

  useEffect(() => {
    if (isError) {
      notification.error({
        message: 'Применение опции не удалось, попробуйте позже',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Данные внесены успешно',
      });
    }
  }, [isSuccess]);

  return (
    <>
      <HeaderBar
        getCurrentItem={getCurrentItem}
        id={id}
        pickerData={pickerData}
        steps={steps}
        setActiveConfigItem={setActiveConfigItem}
        mode={mode}
        refetch={refetchData}
        handleApply={handleApply}
      />
      <MatrixTable
        data={configItems}
        stepData={stepData}
        currentItem={currentItem}
        currentConfig={currentConfig}
        refetch={refetch}
        isApplyOptionLoading={isLoading}
        dataSource={dataSource}
        setDataSource={setDataSource as (newVal: unknown) => void}
        optionsData={optionsData}
        activeConfigItem={activeConfigItem}
        setActiveConfigItem={setActiveConfigItem}
        showOptionImages={true}
        allowExpertLevel={true}
        setConfigurationProfit={setConfigurationProfit}
      />
      <div className="modal__controls flex items-center justify-end gap-[25px]">
        <div className="controls__col self-start">
          <span className="block text-[14px] font-light mb-2">
            количество выбранной техники:
          </span>
          <span className="block text-[18px] font-semibold">
            {dataSource?.length} ед.
          </span>
        </div>
        <div className="controls__col self-start">
          <span className="block text-[14px] font-light mb-2">
            стоимость выбранных опций:
          </span>
          <span className="block text-[18px] font-semibold">
            {addSpacesInNumbers(Number(configurationProfit || 0))} ₽
          </span>
        </div>
        <div className="controls__col">
          <button
            disabled={
              isConfigItemsLoading ||
              isLoading ||
              isButtonsDisabled ||
              isFetching
            }
            className="bg-[#66B28A] mx-4 py-3 px-8 text-[#fff] cursor-pointer active:bg-[#66B28A]/[.8] hover:text-[#fff] select-none rounded-[10px] text-[16px]"
            onClick={() => handleApply(true)}>
            Сохранить конфигурацию
          </button>
          {stepData.current_step !== stepData.steps_count && (
            <button
              disabled={
                isConfigItemsLoading ||
                isLoading ||
                isButtonsDisabled ||
                isFetching
              }
              className="bg-[#66B28A] disabled:opacity-25 disabled:cursor-not-allowed py-3 px-8 text-[#fff] cursor-pointer active:bg-[#66B28A]/[.8] hover:text-[#fff] select-none rounded-[10px] text-[16px]"
              onClick={() => handleApply()}>
              Продолжить
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '-14%',
          right: '3%',
          height: '40px',
          width: '40px',
        }}>
        <ScrollToRightButton />
      </div>
    </>
  );
};

interface IExpertMode {
  open: boolean;
  closeFn: () => void;
  id?: number | string;
  stepData: IInitialStepState;
  steps: IStep[];
  currentItem: ISelectedBunker | null;
  currentConfig: () => ICurrentConfigItem;
  activeConfigItem: number;
  setActiveConfigItem: (value: React.SetStateAction<number>) => void;
  optionsData: IOptionsData;
  dataSource: unknown;
  setDataSource: (val: unknown) => void;
  pickerData: any;
  getCurrentItem: (
    pickerData: any,
    id: undefined | string | number
  ) => ISelectedBunker | null;
  mode: 'expert';
  refetchData?: () => void;
}
