import { Col, Modal, Row } from 'antd';
import { Button } from '@shared/index';
import { useStore } from 'effector-react';
import {
  $catalogDialogModal,
  catalogDialogState,
  openSourceModal,
} from '@/configurator/model';
import styles from './index.module.sass';
import { BunkerCard } from '@/configurator/components/Catalog/components/BunkerCard';
import { bunkerImageByModel } from '@/common/utils/bunker-image-by-model.util';
import { DiContainer } from '@/di/di-container';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { useGetAvailableBunkersQuery } from '@/services/configurator.service';
import { useGetPickerDetailsQuery } from '@/services/picker.service';

const clientStorageService = DiContainer.get(ClientStorageService);

export function Catalog() {
  const { data, isSuccess } = useGetAvailableBunkersQuery({});
  const { data: pickerData } = useGetPickerDetailsQuery(
    clientStorageService.getUUID()
  );

  const modalState = useStore<boolean>($catalogDialogModal);

  const handleCancel = () => {
    catalogDialogState(false);
    openSourceModal();
  };

  return (
    <Modal
      open={modalState}
      onCancel={() => catalogDialogState(false)}
      width="90%"
      closable={true}
      footer={false}>
      <div>
        <div className="flex items-center gap-[16px] justify-between mb-[32px]">
          <h2 className="font-semibold text-[18px]">Каталог техники ООО “Лилиани”</h2>
          <div className="mr-[30px]">
            <Button
              key="back"
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
              className="text-[#4b5563] bg-gray-[#f3f4f6] active:opacity-50 mr-2">
              Назад
            </Button>
            <Button
              key="submit"
              onClick={() => catalogDialogState(false)}
              style={pickerData?.data?.length ? { cursor: 'pointer' } : {}}
              disabled={!pickerData?.data?.length}>
              Конфигурировать
            </Button>
          </div>
        </div>
        <div className={styles.cardBody}>
          <Row gutter={[16, 24]}>
            {data
              ? data?.data?.map((data: any, i: number) => (
                  <Col key={i} className="gutter-row" span={8}>
                    <BunkerCard
                      idx={i}
                      id={+data?.id}
                      pickerId={
                        pickerData?.data?.find(
                          (item: any) =>
                            item.bunker.id === +data?.id &&
                            (!item.configured || !item.can_configured)
                        )?.id || 0
                      }
                      model={data?.attributes?.bunker_model}
                      description={data?.attributes?.description}
                      technicalParams={data?.attributes?.technical_params}
                      count={
                        pickerData?.data?.find(
                          (item: any) =>
                            item.bunker.id === +data?.id &&
                            (!item.configured || !item.can_configured)
                        )?.quantity || 0
                      }
                      image={
                        data?.attributes?.bunker_image_url
                          ? data?.attributes?.bunker_image_url
                          : ''
                      }></BunkerCard>
                  </Col>
                ))
              : ''}
          </Row>
        </div>
      </div>
    </Modal>
  );
}
