//@ts-nocheck
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { DiContainer } from '@/di/di-container';

import {
  Button,
  Collapse,
  CollapseProps,
  Divider,
  Spin,
  Typography,
  notification,
} from 'antd';
import { LabelCollapse } from '@/shoppingCart/components/shoppingCart/components/labelCollapse/label-collapse';
import { ModificationCard, isAddressFilter, isLegalFilter, IShoppingCartItem } from '@/shared';

import './index.styles.scss';
import { DeliveryAndReceipt } from '../components/DeliveryAndReceipt/index.component';
import { MenuItems, useMenu } from '../hooks/use-menu';
import { LegalInformation } from '../components/LegalInformation/index.component';
import { OrderContacts } from '../components/OrderContacts/index.component';
import { useGetProfileQuery } from '@/profile/services/profile.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/services/store';
import { usePlaceNewOrderMutation } from '../services';
import { clearOrderItems } from '@/services/slices/orderSlice';
import { useForm } from 'antd/es/form/Form';
import { useFormValues } from '../hooks/use-form-values';
import { useGetShoppingCartDetailsQuery } from '@/shoppingCart/services/shopping-cart.service';

const clientStorageService = DiContainer.get(ClientStorageService);

const farestMenuItemFields = {
  [MenuItems.orderContacts]: 'fio',
  [MenuItems.deliveryInfo]: 'comments',
  [MenuItems.legalInfo]: 'kpp',
};

export const OrdersView: FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = clientStorageService.getUUID();
  const { city, address_line, region, deliveryBy, phone, email, fio } =
    useFormValues(form);
  const [activeKeys, setActiveKeys] = useState({
    orderContacts: true,
    legalInfo: true,
    deliveryInfo: true,
  });
  useEffect(() => {
    setActiveKeys({
      orderContacts: false,
      legalInfo: false,
      deliveryInfo: false,
    });
  }, []);
  const scrollToInput = (key: MenuItems) => {
    form.scrollToField(farestMenuItemFields[key], {
      behavior: 'smooth',
      block: 'center',
    });
  };
  const { Menu } = useMenu({ scrollToInput, setActiveKeys });

  const { orderItems } = useSelector((state: RootState) => state.order);
  // useEffect(() => {
  //   if (!orderItems?.length) {
  //     navigate('/configurator');
  //     return;
  //   }
  // }, [orderItems]);

  const [placeNewOrder] = usePlaceNewOrderMutation();

  const { refetch: refetchCart } = useGetShoppingCartDetailsQuery({
    session: clientStorageService.getUUID(),
    sharedId: new URLSearchParams(window.location.search).get('shared_uuid')
  });
  const { data: profileData, isLoading } = useGetProfileQuery(session);

  if (isLoading) {
    return (
      <Spin
        className="flex h-full justify-center items-center"
        spinning={true}
        size="large"></Spin>
    );
  }

  const userLegal = profileData?.included.filter(isLegalFilter) || [];
  const userAddresses = profileData?.included.filter(isAddressFilter) || [];

  const currentLegal =
    userLegal.find((legal) => legal?.attributes?.current) || userLegal[0];

  const legalAdressesIds = currentLegal?.relationships?.addresses?.data?.map(
    (el) => el.id
  );

  const currentAddress =
    userAddresses.find(
      (address) =>
        address?.attributes?.current && legalAdressesIds?.includes(address.id)
    ) ||
    userAddresses.find((address) => legalAdressesIds?.includes(address.id)) ||
    userAddresses[0];

  const totalPrice =
    (orderItems as any)?.reduce((acc: number, curr: IShoppingCartItem) => acc + curr.total, 0) || 0;

  const createOrder = async () => {
    try {
      await form.validateFields();
    } catch (e: any) {
      notification.error({
        message: 'Заполните обязательные поля',
      });
      form.scrollToField(e.errorFields[0].name[0], {
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
    const ids = orderItems?.reduce(
      (acc, curr) =>
        acc + curr.items.reduce((ac, cur) => ac + cur.id + ',', '') + '',
      ''
    ) as string;

    placeNewOrder({
      ids,
      address: currentAddress,
      legal: currentLegal,
      profile: profileData,
      formItems: form.getFieldsValue(),
    })
      .unwrap()
      .then((order) => {
        dispatch(clearOrderItems());
        notification.success({
          message:
            'Заказ оформлен успешно. Спасибо за покупку. Менеджер свяжется с вами в ближайшее время.',
        });
        navigate(`/order_list/${order.data.id}`);
      })
      .catch(() =>
        notification.error({
          message:
            'Что-то пошло не так. Попробуйте оформить заказ позже или обратитесь в службу поддержки',
        })
      );
    refetchCart();
  };

  // ORDERS TEST
  if (!orderItems?.length) {
    return (
      <div className="order-page text-center w-full py-40">
        <h1 className="text-[#7E89A5]">Нет заказа для оформления</h1> <br />
        <Button
          className="my-3 bg-[#007E3C] text-white hover:bg-white"
          onClick={() => navigate('/configurator')}>
          Перейти в конфигуратор
        </Button>
      </div>
    );
  }

  const items: CollapseProps['items'] = orderItems?.map((mod) => {
    return {
      key: mod.picker_id,
      label: <LabelCollapse modification={mod} quantity={mod.items.length} />,
      children: (
        <div className="collapse-child">
          {mod?.items?.map((el) => (
            <ModificationCard bunker={el} key={el.id} />
          ))}
        </div>
      ),
    };
  });

  const collapseButton = (isActive: boolean | undefined) => (
    <div
      style={{
        color: '#007E3C',
        position: 'relative',
        right: '20px',
        top: '20px',
      }}>
      {isActive ? 'Свернуть' : 'Развернуть'}
    </div>
  );

  return (
    <>
      <div className="order-page md:container md:mx-auto overflow-y-visible grid grid-cols-3 w-full gap-4">
        <div className="col-span-2">
          {Menu}
          <div className="!min-h-[90vH]">
            <div
              className="flex flex-col gap-[32px]"
              style={{ marginBottom: '50px' }}>
              <Collapse
                style={{
                  boxSizing: 'border-box',
                  borderRadius: '24px',
                  background: '#EEF2FD',
                }}
                expandIcon={(props) => collapseButton(props!.isActive)}
                className="custom-collapse flex flex-col gap-[32px]"
                expandIconPosition="end"
                defaultActiveKey={orderItems.map((p) => p.picker_id)}
                bordered={false}
                items={items}
              />
              {!isLoading && (
                <OrderContacts
                  form={form}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  profile={profileData!}
                />
              )}
              {!isLoading && (
                <DeliveryAndReceipt
                  form={form}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  address={currentAddress}
                />
              )}
              {!isLoading && (
                <LegalInformation
                  form={form}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  legal={currentLegal}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1 mt-[74px]">
          <div
            className="bg-white p-5 rounded-[32px] flex"
            style={{
              border: '1px solid #AAB3CB',
              justifyContent: 'space-between',
              flexDirection: 'column',
              gap: '16px',
            }}>
            <h1 className="font-bold">Доставка и получение</h1>
            <div className="flex" style={{ justifyContent: 'space-between' }}>
              <Typography>
                Способ доставки:&nbsp;
                <span className="font-bold">
                  {deliveryBy === 'pickup'
                    ? 'Самовывоз'
                    : 'Доставка производителем'}
                </span>
              </Typography>
            </div>
            <Typography className="text-xm">
              {region},&nbsp;{city},&nbsp;{address_line}
            </Typography>
            <Divider style={{ margin: '0px' }} />
            <Typography className="font-bold">Контактное лицо</Typography>
            <Typography>
              Получатель: &nbsp;
              <span className="font-bold">{fio}</span>
            </Typography>
            <Typography className="text-xm">
              {phone}&nbsp;{email}
            </Typography>
          </div>
          <div
            className="bg-white p-5 rounded-[32px] flex mt-5"
            style={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              gap: '16px',
              border: '1px solid #AAB3CB',
            }}>
            <div className="grid grid-cols-5 gap-[16px] items-center">
              <Button
                style={{ width: '100%' }}
                className="my-5 py-[15px] h-[56px] font-bold fs-[20px] bg-[#007E3C] text-white hover:bg-[#E1F3EA] col-span-3"
                onClick={createOrder}>
                Оформить заказ
              </Button>
              <Typography className="col-span-2">
                итого: <br />
                {totalPrice ? (
                  <span className="font-bold text-xl">
                    {addSpacesInNumbers(totalPrice)}₽
                  </span>
                ) : (
                  <span>0₽</span>
                )}
              </Typography>
            </div>
            <h2 className="text-xs">
              нажимая на кнопку, вы соглашаетесь с политикой конфиденциальности
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
