import { ReactNode, useState } from 'react';
import { Collapse, Divider } from 'antd';

import { IShoppingCartStep } from '@/shared/types/shopping-cart.types';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';

import placeholder from '@/common/images/placeholder.png';

import { BunkerModification } from '@/shoppingCart/components/shoppingCart/components/labelCollapse/components/bunkerModification/bunker-modification';
import './bunker-card.style.scss';

interface IProps {
  bunker: IShoppingCartStep;
  footerExtraOptions?: ReactNode;
}

export const ModificationCard = ({ bunker, footerExtraOptions }: IProps) => {
  const [extraOptionsKeys, setExtraOptionsKeys] = useState<string[]>([
    bunker.id.toString(),
  ]);
  const [optionsKeys, setOptionsKeys] = useState<string[]>([
    bunker.id.toString(),
  ]);

  const options = {
    key: bunker.id,
    label: (
      <p className="text-[#7E89A5]">
        {`опции включенные в базовую модификацию (${
          bunker ? bunker.options?.steps?.length : '0'
        }шт)`}
      </p>
    ),
    children: (
      <div className="addons-prices">
        {bunker.options?.steps?.length ? (
          bunker.options?.steps.map((el, idx) => (
            <BunkerModification
              key={idx}
              bunkerModificationDescription={el.name}
            />
          ))
        ) : (
          <p className="text-[#7E89A5]">опции отсутствуют</p>
        )}
      </div>
    ),
  };

  const extraOptions = {
    key: bunker.id,
    label: (
      <p className="text-[#7E89A5]">
        {`дополнительные опции (${
          bunker ? bunker.extra_options.steps.length : '0'
        })`}
      </p>
    ),
    children: (
      <div className="addons-prices">
        {bunker.extra_options.steps.length ? (
          bunker.extra_options.steps.map((el, idx) => (
            <div
              key={idx}
              className="flex relative"
              style={{ justifyContent: 'space-between' }}>
              <BunkerModification
                bunkerModificationDescription={el.name}
                // priceOfModification={addSpacesInNumbers(el.cost)}
              />
              {/* <div className="absolute top-0 left-[173%] w-[100px]">
                {addSpacesInNumbers(el.cost)}
              </div> */}
            </div>
          ))
        ) : (
          <p className="text-[#7E89A5]">дополнительные опции отсутствуют</p>
        )}
      </div>
    ),
  };

  const onCollapseClick = (ids: string | string[]) => {
    setOptionsKeys([...ids]);
  };

  const onExtraCollapseClick = (ids: string | string[]) => {
    setExtraOptionsKeys([...ids]);
  };

  return (
    <div className="children-collapse">
      <div className="pt-14">{bunker.number}</div>
      <img
        src={bunker.bunker_image_url || placeholder}
        className="w-[212px] h-[156px] select-none  bg-transparent object-contain"
      />
      <div
        className="description-bunker-container pb-7 mb-2"
        style={{ borderBottom: '1px solid #474747' }}>
        <p className="font-bold">{bunker.name}</p>
        <div className="flex" style={{ justifyContent: 'space-between' }}>
          <div className="text-[#7E89A5]">стоимость базовой модели</div>
          <div className="text-s">{addSpacesInNumbers(bunker.base_price)}</div>
        </div>
        <div className="flex" style={{ justifyContent: 'space-between' }}>
          <Collapse
            // expandIcon={(props) => collapseButton(props.isActive)}
            activeKey={optionsKeys}
            onChange={onCollapseClick}
            accordion
            className="steps-collapse"
            bordered={false}
            items={[options]}
            expandIconPosition="end"
          />
        </div>
        <div className="flex" style={{ justifyContent: 'space-between' }}>
          <Collapse
            // expandIcon={(props) => collapseButton(props.isActive)}
            activeKey={extraOptionsKeys}
            accordion
            onChange={onExtraCollapseClick}
            className="steps-collapse"
            bordered={false}
            items={[extraOptions]}
            expandIconPosition="end"
          />
          <div>
            <div className="font-semibold text-base">
              {addSpacesInNumbers(bunker.extra)}
            </div>
            {extraOptionsKeys.length && bunker.extra_options.steps.length ? (
              bunker.extra_options.steps.map((el, idx) => (
                <div key={idx} className="font-semibold text-sm pt-1">
                  {addSpacesInNumbers(el.cost)}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
        <Divider style={{ margin: '0px' }} />
        <div
          className="flex pt-5"
          style={{
            justifyContent: footerExtraOptions ? 'space-between' : 'flex-end',
          }}>
          {bunker.can_configured && footerExtraOptions}
          <div className="font-semibold">
            всего: {addSpacesInNumbers(bunker.amount)}
          </div>
        </div>
      </div>
    </div>
  );
};
