import { useNavigate } from 'react-router-dom';
import {
  useAddToShoppingCartMutation,
  useDeleteBunkerFromStackMutation,
  useGetShoppingCartDetailsQuery,
} from '@/shoppingCart/services/shopping-cart.service';
import CartActiveSvg from './images/cart-active.svg';
import { DiContainer } from '@/di/di-container';
import {
  useGetPickerDetailsQuery,
  useMarkBunkerAsConfiguredMutation,
} from '@/services/picker.service';
import { Counter } from '../../shared/counter';
import styles from './index.module.sass';
import { Modal, notification } from 'antd';
import { ClientStorageService } from '@/common/services/client-storage.service';
import { IAttributes } from '@/configurator/ts';
import CartSvg from './images/cart.svg';
import closeIcon from './images/close.svg';
import placeholder from '@/common/images/placeholder.png';
import { Button } from '../../shared';
import cn from 'classnames';
import { ISelectedBunker } from '@/configurator/ts/catalog.types';
import { addSpacesInNumbers } from '@/configurator/helpers/add-spaces-in-numbers';
import { useEffect, useState } from 'react';

const clientStorageService = DiContainer.get(ClientStorageService);

interface ISelectedBunkerCard {
  bunker: IAttributes;
  count: number;
  technique: ISelectedBunker;
  image?: string;
  removeFoo?: (id: number, uuid: string) => void;
  uniqueId: number;
  setCount: (val: number) => void;
  countLoading?: boolean;
  displaySelectModeFn?: () => void;
  setActivePickerId?: (val: number) => void;
  idx: number;
  configured?: boolean;
  canConfigured?: boolean;
  isInCart: boolean;
}

export function SelectedBunkerCard({
  bunker,
  count,
  removeFoo,
  uniqueId,
  setCount,
  countLoading,
  displaySelectModeFn,
  setActivePickerId,
  isInCart,
  idx,
  image,
  configured,
  canConfigured,
  technique,
}: ISelectedBunkerCard) {
  const navigate = useNavigate();

  const [isButtonsDisabled, setButtonsDisabled] = useState(false);

  const [
    markBunkerAsConfigured,
    {
      isError: isConfiguredError,
      isSuccess: isConfiguredSuccess,
      isLoading: isConfiguredLoading,
    },
  ] = useMarkBunkerAsConfiguredMutation();

  const { refetch, isFetching } = useGetPickerDetailsQuery(
    clientStorageService.getUUID()
  );

  const [
    addToCart,
    { isLoading: isAddToCartLoading, isError: isAddToCartError },
  ] = useAddToShoppingCartMutation();
  const [
    removeFromCart,
    { isLoading: isRemoveFromCartLoading, isError: isRemoveFromCartError },
  ] = useDeleteBunkerFromStackMutation();

  const {
    data: cartData,
    isLoading: isCartLoading,
    refetch: refetchCart,
  } = useGetShoppingCartDetailsQuery({
    session: clientStorageService.getUUID(),
    sharedId: new URLSearchParams(window.location.search).get('shared_uuid'),
  });

  useEffect(() => {
    refetchCart && refetchCart();
  }, [refetchCart, cartData]);

  const handleConfigure = () => {
    technique.previously_configured &&
      navigate(`/feed/${uniqueId}?mode=expert`);
    displaySelectModeFn && displaySelectModeFn();
    setActivePickerId && setActivePickerId(uniqueId);
    if (technique.in_shopping_cart) {
      removeFromCart({
        id: String(uniqueId),
        session: clientStorageService.getUUID(),
      });
    }
    localStorage.setItem('order', '[]');
  };

  const handleNotificationRedirect = () => {
    notification.destroy();
    navigate('/shopping-cart');
  };

  const handleAddOrGoToCart = async () => {
    if (!configured)
      return notification.warning({
        message:
          'Для добавления позиции в корзину нужно завершить конфигурацию',
      });

    if (isInCart) {
      await addToCart({
        id: String(uniqueId),
        session: clientStorageService.getUUID(),
      });
      navigate(`/shopping-cart`);
    }

    if (!isInCart && configured) {
      try {
        await addToCart({
          id: String(uniqueId),
          session: clientStorageService.getUUID(),
        });
        notification.success({ message: 'Техника добавлена в корзину' });
        notification.open({
          message: (
            <>
              <span className={styles.notificationTextBold}>
                {bunker?.bunker_model} ({count} шт)
              </span>{' '}
              {count === 1 ? 'добавлен' : 'добавлены'} в корзину
            </>
          ),
          placement: 'bottom',
          closeIcon: false,
          btn: (
            <Button
              className="cursor-pointer w-60 mb-[25px] mr-[4px]"
              onClick={handleNotificationRedirect}>
              перейти в корзину
            </Button>
          ),
          className: styles.notification,
          style: {
            padding: '4px',
            minWidth: 715,
            height: 80,
            borderRadius: 18,
          },
        });
        refetchCart();
      } catch (e: any) {
        notification.error({
          message: 'Ошибка. Попробуйте добавить в корзину ещё раз',
        });
      } finally {
        setButtonsDisabled(false);
      }
    }
    refetch();
  };

  const handleChangeConfiguration = () => {
    if (uniqueId) {
      markBunkerAsConfigured({
        id: uniqueId,
        configured: false,
        session: clientStorageService.getUUID(),
      }).then(
        (res: any) =>
          res.data.status === 'success' &&
          navigate(`/feed/${uniqueId}?mode=expert`)
      );
    }
  };

  const handleRemove = () => {
    if (uniqueId && removeFoo) {
      markBunkerAsConfigured({
        id: uniqueId,
        configured: false,
        session: clientStorageService.getUUID(),
      })
        .then(
          (res: any) =>
            res.data.status === 'success' &&
            removeFoo(uniqueId, clientStorageService.getUUID())
        )
        .then(() => {
          const event = new Event('onShoppingCartUpdate');
          document.dispatchEvent(event);
        });
    }
  };

  const handleOpenRemoveModal = () => {
    Modal.confirm({
      title: 'Подтвердите действие',
      content:
        'Данная операция удалит технику из конфигуратора(в т.ч. из корзины). Вы уверены, что хотите это сделать?',
      okText: 'да',
      cancelText: 'назад',
      onOk: () => {
        handleRemove();
        refetchCart();
      },
    });
  };

  return (
    <div className={cn(styles.selectedBunkerCard)}>
      <img
        className="w-[20px] h-[20px] absolute right-[20px] top-[20px] cursor-pointer active:opacity-75"
        src={closeIcon}
        onClick={handleOpenRemoveModal}
      />
      <img
        src={image || placeholder}
        className={`w-full mb-[16px] h-[243px] select-none adaptive_image ${cn(styles.adaptive_image)}`}
      />
      <div className={'font-bold h-[48px]'}>{bunker?.bunker_model}</div>
      <div className={styles.counter}>
        {configured && canConfigured ? (
          <div className={'font-bold mt-[14px]'}>{count} шт</div>
        ) : (
          <Counter
            minValue={0}
            count={count}
            setCount={setCount}
            loading={
              countLoading ||
              isCartLoading ||
              isConfiguredLoading ||
              isAddToCartLoading ||
              isRemoveFromCartLoading
            }
            idx={idx}
          />
        )}
        <div className="select-none">
          {configured
            ? `итого:  ${addSpacesInNumbers(technique.total_amount)} ₽`
            : `не сконфигурировано`}
        </div>
      </div>

      <div className={styles.buttons}>
        {canConfigured ? (
          configured ? (
            <div
              onClick={handleChangeConfiguration}
              className="font-bold text-[#007E3C] cursor-pointer active:opacity-75 select-none">
              изменить конфигурацию
            </div>
          ) : (
            <Button
              key="submit1"
              className="bg-green-secondary"
              onClick={handleConfigure}>
              {technique.previously_configured
                ? 'продолжить конфигурировать'
                : 'конфигурировать'}
            </Button>
          )
        ) : (
          <></>
        )}
        <Button
          key="submit2"
          disabled={
            isButtonsDisabled ||
            countLoading ||
            isCartLoading ||
            isConfiguredLoading ||
            isAddToCartLoading ||
            isRemoveFromCartLoading
          }
          className={
            !configured
              ? 'text-[#AAB3CB] bg-[#DFE6FA] active:bg-[#DFE6FA] flex items-center justify-center'
              : 'cursor-pointer text-[#fff] bg-[#409BFF] active:bg-[#409BFF]/[0.85] flex items-center justify-center'
          }
          style={{
            backgroundColor: !configured
              ? '#DFE6FA'
              : !isInCart
                ? '#409BFF'
                : '#66B28A',
          }}
          onClick={() => {
            setButtonsDisabled(true);
            handleAddOrGoToCart();
          }}>
          <>
            <img
              className="inline-block mr-[8px]"
              src={!configured ? CartSvg : CartActiveSvg}
            />
            <span style={{ color: !configured ? '#AAB3CB' : '#fff' }}>
              {isInCart ? 'Перейти в корзину' : `Добавить в корзину`}
            </span>
          </>
        </Button>
      </div>
    </div>
  );
}
